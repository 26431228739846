body {
  direction: rtl;
  unicode-bidi: embed;
}

.site-header .user-log-in h3 {
  margin: 0 15px 0 0;
}

.single-post .author-social .author img {
  margin-left: 10px;
  margin-right: 0;
}
.single-post .author-social .social h4 {
  margin: 0 0 0 20px;
}
.single-post .author-social .social .share-post ul li:last-child {
  margin-left: 0;
  margin-right: 5px;
}

#all-posts .card .card-body .entry-meta .author img {
  margin-right: 0;
  margin-left: 10px;
}
#all-posts .card .card-body .tags li {
  margin-right: 0;
  margin-left: 5px;
}

#bottom-contact .wrap-form form input[type=email],
.lp-contact-form .wrap-form form input[type=email],
.asset-form .wrap-form form input[type=email] {
  text-align: right;
}

.asset-info .home-content li::before {
  margin-left: 10px;
  margin-right: 0;
}
.asset-info .download-blueprint svg {
  margin-right: 0;
  margin-left: 10px;
}
.asset-info .bottom .make-appointment {
  margin-right: 0;
  margin-left: auto;
}
.asset-info .bottom .circle {
  margin-right: 20px;
  margin-left: 0;
}

.assets-slider .navigation {
  right: 5px;
  left: auto;
}

.more-assets .assets .item .card .card-img .ribbon {
  left: 15px;
  right: auto;
}

#seller-assets-wrapper .asset .card .card-body .bottom .btn {
  margin-left: 5%;
  margin-right: 0;
}

#filter-assets form .rooms:after,
#filter-assets form .price-range-select:after {
  left: 15px;
  right: auto;
}
#filter-assets .nice-select {
  height: 52px;
  text-align: right !important;
  padding-right: 2.5rem;
}
#filter-assets .nice-select .option {
  text-align: right;
}
#filter-assets .nice-select:after {
  left: 15px;
  right: auto;
}

#bottom-contact .wrap-form form .nice-select,
.lp-contact-form .wrap-form form .nice-select,
.asset-form .wrap-form form .nice-select {
  height: 52px;
  text-align: right !important;
  padding-right: 15px;
}
#bottom-contact .wrap-form form .nice-select .option,
.lp-contact-form .wrap-form form .nice-select .option,
.asset-form .wrap-form form .nice-select .option {
  text-align: right;
}
#bottom-contact .wrap-form form .nice-select:after,
.lp-contact-form .wrap-form form .nice-select:after,
.asset-form .wrap-form form .nice-select:after {
  left: 15px;
  right: auto;
}

#home-map .mini-search .nice-select {
  height: 52px;
  text-align: right !important;
  padding-right: 35px;
}
#home-map .mini-search .nice-select .option {
  text-align: right;
}
#home-map .mini-search .nice-select::after {
  left: 15px;
  right: auto;
}

.latest-articles .card .card-body .tags li {
  margin-right: 0;
  margin-left: 0;
}

#open-jobs .all-jobs .job {
  text-align: right;
}
#open-jobs .all-jobs .job .content ul,
#open-jobs .all-jobs .job .content ol {
  padding-right: 1rem;
}

.modal .modal-dialog .modal-content .modal-header .modal-title {
  text-align: right;
  margin-left: 15px;
  margin-right: 0;
}

.site-footer .footer-main-section .social ul li:first-child {
  margin-right: 0;
  margin-left: 5px;
}
.site-footer .footer-main-section .social ul li:last-child {
  margin-right: 5px;
  margin-left: 0;
}
.site-footer .copy ul li::after {
  left: 0;
  right: auto;
}

@media screen and (min-width: 768px) {
  .site-header .user-log-in .user-menu-box {
    right: auto;
    left: 0;
  }
  .assets-slider .navigation {
    right: 15px;
    left: auto;
  }
}
@media screen and (min-width: 992px) {
  .site-header .main-navigation ul li ul {
    right: -999em;
    left: auto;
  }
  .site-header .main-navigation ul li:hover > ul {
    right: 0;
  }
  .site-header .main-navigation ul li.menu-item-has-children > .sub-menu-arrow {
    margin-right: 5px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .asset-info .bottom .circle {
    margin-right: 0;
  }
}