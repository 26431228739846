// RTL Stylings
body {
  direction: rtl;
  unicode-bidi: embed;
}
// Bootstrap Overrides
.form-select {
  // background-position: left 0.75rem center;
  // padding: 0.375rem 0.75rem 0.375rem 2.25rem;
}

// Header
.site-header {
  .main-navigation {
    ul {
      li {
        &.menu-item-has-children {
          > .sub-menu-arrow {
          }
        }
      }
    }
  }
  .user-log-in {
    h3 {
      margin: 0 15px 0 0;
    }
  }
}

// Single Post
.single-post {
  .author-social {
    .author {
      img {
        margin-left: 10px;
        margin-right: 0;
      }
    }
    .social {
      h4 {
        margin: 0 0 0 20px;
      }
      .share-post {
        ul {
          li {
            &:last-child {
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

// Blog
#all-posts {
  .card {
    .card-body {
      .entry-meta {
        .author {
          img {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
      .tags {
        li {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

// Bottom Contact
#bottom-contact,
.lp-contact-form,
.asset-form {
  .wrap-form {
    form {
      input {
        &[type="email"] {
          text-align: right;
        }
      }
    }
  }
}

// Asset
.asset-info {
  .home-content {
    li {
      &::before {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  .download-blueprint {
    svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .bottom {
    .make-appointment {
      margin-right: 0;
      margin-left: auto;
    }
    .circle {
      margin-right: 20px;
      margin-left: 0;
    }
  }
}
.assets-slider {
  .navigation {
    right: 5px;
    left: auto;
    // .swiper-button-prev {
    //   left: auto;
    //   right: 0;
    // }
    // .swiper-button-next {
    //   left: 0;
    //   right: auto;
    // }
  }
}

.more-assets {
  .assets {
    .item {
      .card {
        .card-img {
          .ribbon {
            left: 15px;
            right: auto;
          }
        }
      }
    }
  }
}
#seller-assets-wrapper {
  .asset {
    .card {
      .card-body {
        .bottom {
          .btn {
            margin-left: 5%;
            margin-right: 0;
          }
        }
      }
    }
  }
}

#filter-assets {
  form {
    .rooms,
    .price-range-select {
      // text-align: right;
      &:after {
        left: 15px;
        right: auto;
      }
      .rooms-list {
        .nice-select {
        }
      }
    }
  }
  .nice-select {
    height: 52px;
    text-align: right !important;
    padding-right: 2.5rem;
    .option {
      text-align: right;
    }

    &:after {
      left: 15px;
      right: auto;
    }
  }
}

#bottom-contact,
.lp-contact-form,
.asset-form {
  .wrap-form {
    form {
      .nice-select {
        height: 52px;
        text-align: right !important;
        padding-right: 15px;
        .option {
          text-align: right;
        }

        &:after {
          left: 15px;
          right: auto;
        }
      }
    }
  }
}

#home-map {
  .mini-search {
    .btn {
      // &.sale {
      //   border-top-left-radius: 0;
      //   border-bottom-left-radius: 0;
      // }
      // &.sold {
      //   border-top-right-radius: 0;
      //   border-bottom-right-radius: 0;
      // }
    }
    .nice-select {
      height: 52px;
      text-align: right !important;
      padding-right: 35px;
      .option {
        text-align: right;
      }
      &::after {
        left: 15px;
        right: auto;
      }
    }
  }
}

.latest-articles {
  .card {
    .card-body {
      .tags {
        li {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}

// Careers
#open-jobs {
  .all-jobs {
    .job {
      text-align: right;
      .content {
        ul,
        ol {
          padding-right: 1rem;
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          text-align: right;
          margin-left: 15px;
          margin-right: 0;
        }
      }
    }
  }
}

.site-footer {
  .footer-main-section {
    .social {
      ul {
        li {
          &:first-child {
            margin-right: 0;
            margin-left: 5px;
          }
          &:last-child {
            margin-right: 5px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .copy {
    ul {
      li {
        &::after {
          left: 0;
          right: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .site-header {
    .user-log-in {
      .user-menu-box {
        right: auto;
        left: 0;
      }
    }
  }
  .assets-slider {
    .navigation {
      right: 15px;
      left: auto;
    }
  }
}
@media screen and (min-width: 992px) {
  .site-header {
    .main-navigation {
      ul {
        li {
          ul {
            right: -999em;
            left: auto;
          }
          &:hover {
            > ul {
              right: 0;
            }
          }
          &.menu-item-has-children {
            > .sub-menu-arrow {
              margin-right: 5px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .asset-info {
    .bottom {
      .circle {
        margin-right: 0;
      }
    }
  }
}
